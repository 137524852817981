<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 684"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="soccer-chalkboard perspective3d"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 684" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Flechas -->
        <defs>
          <marker
            id="arrow-negative"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            markerUnits="strokeWidth"
            orient="auto"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" stroke-width="2" fill="red" />
          </marker>
          <marker
            v-for="c in localCategories"
            :key="`arrow-color-${c.id}`"
            :id="`arrow-${c.id}`"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            markerUnits="strokeWidth"
            orient="auto"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" stroke-width="2" :fill="c.color" />
          </marker>
          <pattern id="fieldBackground" width="1" height="1" viewBox="0 0 100 100" preserveAspectRatio="none">
            <image xlink:href="/images/fieldbg.png" width="100" height="100" preserveAspectRatio="none" id="image2" />
          </pattern>
        </defs>
        <!-- Cancha -->
        <g id="soccer-field">
          <rect opacity=".8" height="684" width="452" fill="url(#fieldBackground)" />
          <g id="g19" transform="matrix(1,0,0,0.96314115,0,12.524864)" stroke="#eeffee" fill="none" stroke-width="3">
            <path d="m 11.22,22.62 v 638.8 h 429.6 V 22.62 Z" id="path4" />
            <path d="m 11.26,342 h 429.4" id="path6" />
            <circle cy="342" cx="226" r="54.799999" id="circle8" />
            <circle cy="342" cx="226" r="2" id="circle10" />
            <g id="a">
              <path
                d="m 9.9,30.07 c 4.85,0 8.82,-4 8.82,-8.9 m 162.5,100.8 a 54.91,54.91 0 0 0 89.6,0 m 76.3,-99.63 v
                99.63 h -242.2 l 0.2,-99.63 m 98.6,0.2 V 6.94 l 44.6,0.003 v 15.6 m -77.9,-0.2 v 34.4 h 111.2 v
                -34.4 m 160.5,7.7 c -4.9,0 -8.8,-4 -8.8,-8.9"
                id="path12"
              />
              <circle cy="90" cx="226" r="2" id="circle14" />
            </g>
            <use xlink:href="#a" transform="scale(1,-1)" y="-684" x="0" width="100%" height="100%" />
          </g>
        </g>
        <!-- Jugadas de 3 puntos -->
        <g v-for="play in graphPlays" :key="play.id" :id="`play-${play.playId}`">
          <line
            :x1="`${play.x1}%`"
            :y1="`${play.y1}%`"
            :x2="`${play.x2}%`"
            :y2="`${play.y2}%`"
            :stroke="play.arrowColor"
            stroke-width="2"
          />
          <line
            :x1="`${play.x2}%`"
            :y1="`${play.y2}%`"
            :x2="`${play.x3}%`"
            :y2="`${play.y3}%`"
            :stroke="play.arrowColor"
            stroke-width="2"
            :marker-end="play.arrowMarkerColor"
          />
          <g @mouseover="onMouseOver($event, play)" @mouseleave="onMouseLeave" class="is-pointer">
            <rect
              :x="`${play.x1}%`"
              :y="`${play.y1}%`"
              transform="translate(-11, -11)"
              height="22"
              width="22"
              stroke-width="2"
              fill="white"
              :stroke="play.markerColor"
              rx="4"
              ry="4"
            />
            <text
              text-anchor="middle"
              dominant-baseline="middle"
              :x="`${play.x1}%`"
              :y="`${play.y1}%`"
              dy="1"
              font-size="12"
              stroke="black"
            >
              {{ play.jersey }}
            </text>
          </g>
        </g>
      </svg>
    </svg>
    <Tooltip :data="data">
      <div class="tooltip-data has-text-left">
        <div><span class="bold">Categoría:</span> {{ dataPlay.isOwnGoal ? 'Autogol' : dataPlay.categoryName }}</div>
        <div><span class="bold">Jugador:</span> {{ dataPlay.playerName }}</div>
        <div><span class="bold">Minuto:</span> {{ dataPlay.minute }}</div>
        <div>
          <span class="bold">Rival:</span>
          <img :src="dataPlay.rivalLogo" width="25" style="vertical-align:middle" />
        </div>
        <div
          v-if="/^[+]?([0-1]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(dataPlay.xGi) && dataPlay.xGi <= 1 && !dataPlay.isOwnGoal"
        >
          <span class="bold">xGi:</span> {{ Number(dataPlay.xGi) | ceilMod(4) }}
        </div>
        <div
          v-if="/^[+]?([0-1]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(dataPlay.xGf) && dataPlay.xGf <= 1 && !dataPlay.isOwnGoal"
        >
          <span class="bold">xGf:</span> {{ Number(dataPlay.xGf) | ceilMod(4) }}
        </div>
      </div>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from '@/components/Elements/Tooltip';
import { mapGetters } from 'vuex';
export default {
  name: 'SoccerChalkboard',
  components: {
    Tooltip,
  },
  props: {
    plays: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    team: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      negativeColor: 'red',
      data: {
        x: 0,
        y: 0,
        visible: false,
      },
      dataPlay: {
        playerName: '',
        minute: 0,
        rivalLogo: '',
        rivalName: '',
        categoryName: '',
        xGi: 0,
        xGf: 0,
        isOwnGoal: false,
      },
    };
  },
  computed: {
    ...mapGetters('nextGeneral', ['homeTeam', 'visitingTeam']),
    /**
     * @author Rigoberto Sanchez
     * @description Metodo para convertir el arreglo de jugadas en un json, para trabajarlo con las demas jugadas, por si cambia la estructura o posicion de los elementos
     */
    preparedPlays() {
      return this.plays.map(p => ({
        ...p,
        playingAs: p.playing_as,
        teamId: p.team_id,
        categoryId: p.category_id,
        matchResult: p.match_result,
        matchLapse: p.matchlapse,
        x1: p.x1 * 100,
        y1: p.y1 * 100,
        x2: p.x2 * 100,
        y2: p.y2 * 100,
        x3: p.x3 * 100,
        y3: p.y3 * 100,
        xGi: p.xG1,
        xGf: p.xG2,
      }));
    },

    /**
     * @author Rigoberto Sanchez
     * @description Metodo para obtener todas las categorias, determinar de cuantos puntos le corresponden, asi como asignar los colres, puntos y categorias golstats
     */
    localCategories() {
      return this.categories.map((c, i) => ({
        id: i,
        name: c.name,
        negative: c.negative || false,
        color: c.color,
        points: c.points,
        numPoints: c.points.length,
        categories: c.categories,
        invert: c.invert || [],
      }));
    },

    /**
     * @author Rigoberto Sanchez
     * @description Metodo para calcular las jugadas que son de 3 puntos,
     * se obtienen las jugadas de la propiedad "plays",
     * y se crea un array con las jugadas correspondientes.
     */
    graphPlays() {
      let categories = this.localCategories.reduce((acc, c) => {
        return [...acc, c.categories];
      }, []);
      let filteredPlays = this.preparedPlays.filter(p => this.findCategoryInPlay(categories, p));
      let reducedPlays = filteredPlays.reduce((accumulator, p) => {
        let category = this.localCategories.find(c => this.findCategoryInPlay(c.categories, p));

        if (category) {
          let result = {
            category: category.id,
            categoryName: category.name,
            arrowColor: category.negative ? this.negativeColor : category.color,
            arrowMarkerColor: category.negative ? `url(#arrow-negative)` : `url(#arrow-${category.id})`,
            markerColor: category.color,
            jersey: p.jersey_number,
            matchlapse: p.matchLapse,
            playerName: p.player_name,
            rivalLogo: p.rival_id,
            x1: category.invert.includes(p.categoryId)
              ? 100 - p[`x${category.points[0]}`]
              : p[`x${category.points[0]}`],
            y1: category.invert.includes(p.categoryId)
              ? 100 - p[`y${category.points[0]}`]
              : p[`y${category.points[0]}`],
            x2: category.invert.includes(p.categoryId)
              ? 100 - p[`x${category.points[1]}`]
              : p[`x${category.points[1]}`],
            y2: category.invert.includes(p.categoryId)
              ? 100 - p[`y${category.points[1]}`]
              : p[`y${category.points[1]}`],
            x3: category.invert.includes(p.categoryId)
              ? 100 - p[`x${category.points[2]}`]
              : p[`x${category.points[2]}`],
            y3: category.invert.includes(p.categoryId)
              ? 100 - p[`y${category.points[2]}`]
              : p[`y${category.points[2]}`],
            momentOfPlay: p.moment_of_play,
            isOwnGoal: category.invert.includes(p.categoryId),
            xGi: p.xGi,
            xGf: p.xGf,
          };

          accumulator.push(result);
        }
        return accumulator;
      }, []);
      return reducedPlays;
    },
  },
  methods: {
    /**
     * @author Alejandro
     * @description There are some categories in format categoryId and r, e.g. 35_r, these categories
     * with _r are rival plays. So these method look for those plays an compare it's team id.
     * @param {Array} categories
     * @param {Object} play
     */
    findCategoryInPlay(categories, play) {
      return categories.find(category => {
        return typeof category === 'number' ? category === play.categoryId : category.includes(play.categoryId);
      });
    },

    /**
     * @author Rigoberto Sanchez
     * @description Metodo para obtener los datos correspondientes a la jugada y mostrarlos en el tooltip
     */
    onMouseOver(event, play) {
      this.dataPlay.playerName = play.playerName || '';
      this.dataPlay.minute = parseInt(play.momentOfPlay.split(':')[0]) || '';
      this.dataPlay.rivalLogo = play.rivalLogo || '';
      this.dataPlay.categoryName = play.categoryName || '';
      this.dataPlay.rivalName = this.team == 'Visiting' ? this.homeTeam.team_name : this.visitingTeam.team_name;
      this.dataPlay.xGi = String(play.xGi);
      this.dataPlay.xGf = String(play.xGf);
      this.dataPlay.isOwnGoal = play.isOwnGoal;
      this.data.x = event.layerX;
      this.data.y = event.layerY;
      this.data.visible = true;
    },

    /**
     * @author Rigoberto Sanchez
     * @description Metodo para indicar que el usuario ha salido del elemento y dejar de mostrar el tooltip
     */
    onMouseLeave() {
      this.data.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 70%;
  margin: 0 auto;
  display: inline-block;
}
.perspective3d {
  transform: perspective(700px) rotateX(25deg) translate3d(0, -50px, 0);
  transform-style: preserve-3d;
  animation: changePerspective3d 0.1s;
}

@keyframes changePerspective3d {
  0% {
    transform: perspective(700px) rotateX(0) translate3d(0, 0, 0);
  }
  20% {
    transform: perspective(700px) rotateX(5deg) translate3d(0, -10px, 0);
  }
  40% {
    transform: perspective(700px) rotateX(10deg) translate3d(0, -20px, 0);
  }
  60% {
    transform: perspective(700px) rotateX(15deg) translate3d(0, -30px, 0);
  }
  80% {
    transform: perspective(700px) rotateX(20deg) translate3d(0, -40px, 0);
  }
  100% {
    transform: perspective(700px) rotateX(25deg) translate3d(0, -50px, 0);
  }
}

.has-gray-background {
  background: #f1f2f2;
}
.is-pointer {
  cursor: default;
}

.tooltip-data {
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}
</style>
