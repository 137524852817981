<template>
  <div class="xg">
    <div class="xg__team-switch">
      <div>
        <span :title="homeTeam.team_name">{{ homeTeam.team_name }}</span>
      </div>
      <BSwitch
        size="is-small"
        type="is-success"
        passive-type="is-success"
        v-model="team"
        true-value="Visiting"
        false-value="Local"
      />
      <div>
        <span :title="visitingTeam.team_name"> {{ visitingTeam.team_name }}</span>
      </div>
    </div>
    <div class="xg__info">
      <div class="xg__info-item" v-for="(item, index) in gxInfoItems" :key="'xg-' + index">
        <div class="xg__info-item-left">{{ item.title + ': ' }}</div>
        <div class="xg__info-item-right">{{ item.value }}</div>
      </div>
    </div>
    <div class="xg__what-is">
      <!-- <div
        @click="openXgVideo"
        class="button xg__what-is-btn is-rounded is-primary"
        :size="displayWidth < 769 ? 'is-small' : ''"
      >
        <span class="xg__what-is-text">¿Qué es <b>xG?</b></span>
        <span class="xg__what-is-icon">
          <i class="mdi mdi-play-circle-outline" />
        </span>
      </div> -->
    </div>
    <div class="xg__chalkboard">
      <SoccerChalkboard :plays="plays" :categories="categories" :team="team" />
    </div>
  </div>
</template>

<script>
import SoccerChalkboard from '@/components/Elements/SoccerChalkboard.vue';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import { normalPlayer } from '@/data/xg/chalkboardCategories';
import { clickVerExpectativaDeGolDeEquipo, clickVerVideoQueEsXg } from '@/utils/analytics';
import { BSwitch } from 'buefy/dist/esm/switch';

export default {
  name: 'Xg',
  components: {
    SoccerChalkboard,
    BSwitch,
  },
  data() {
    return {
      categories: normalPlayer,
    };
  },
  computed: {
    ...mapState('nextGeneral', ['usePastSeasonId', 'pastSeasonId', 'gameId', '']),
    ...mapGetters('xgGeneral', ['plays', 'goalsQty', 'shotsOnGoalQty', 'xG']),
    ...mapGetters('nextGeneral', ['getSeasonId', 'homeTeam', 'visitingTeam', 'showPostMatchData']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('general', ['getPreviewSectionAnalytics']),
    team: {
      get() {
        return this.$store.state.xgGeneral.teamSelected;
      },
      set(newVal) {
        clickVerExpectativaDeGolDeEquipo({
          team_id: this.getTeamId(newVal),
          game_id: this.gameId,
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
          preview_section: this.getPreviewSectionAnalytics,
        });
        this.setTeamSelected(newVal);
      },
    },

    displayWidth() {
      return this.$store.state.displayWidth;
    },

    gxInfoItems() {
      return [
        { title: 'Goles', value: this.goalsQty },
        { title: 'Tiros a Gol', value: this.shotsOnGoalQty },
        { title: 'xG', value: this.xG },
      ];
    },
  },
  watch: {
    visitingTeam: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.fillXgPlays();
        }
      },
    },
  },
  methods: {
    ...mapMutations('general', ['setVideoSrc', 'setVideoModalVisibility']),
    ...mapMutations('xgGeneral', ['setTeamSelected']),
    ...mapActions('xgGeneral', ['getXgPlays']),
    openXgVideo() {
      clickVerVideoQueEsXg(this.getPremiumAccount, this.getPreviewSectionAnalytics, this.getIsGolstats);
      this.setVideoSrc('');
      this.setVideoModalVisibility(true);
    },
    getTeamId(playingAs) {
      let teamId;
      switch (playingAs) {
        case 'Visiting':
          teamId = this.visitingTeam.team_id;
          break;
        case 'Local':
          teamId = this.homeTeam.team_id;
          break;
      }
      return teamId;
    },
    fillXgPlays() {
      if (this.showPostMatchData) {
        this.getXgPlays({
          seasonId: this.gameId,
          localTeamId: this.homeTeam.team_id,
          visitingTeamId: this.visitingTeam.team_id,
          postMatch: this.showPostMatchData,
        });
      } else {
        this.getXgPlays({
          seasonId: this.usePastSeasonId ? this.pastSeasonId : this.getSeasonId,
          localTeamId: this.homeTeam.team_id,
          visitingTeamId: this.visitingTeam.team_id,
        });
      }
    },
  },
  beforeMount() {
    this.fillXgPlays();
  },
};
</script>

<style lang="scss" scoped>
$break-point-max: 800px;
$break-point-min: 801px;

.xg {
  display: grid;
  box-sizing: content-box;
  width: 100%;
  grid-auto-columns: 1fr;

  & > * {
    margin: 0 auto;
  }

  &__team-switch {
    grid-area: switch;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    background: #f9f9f9;
    font-size: 1.2rem;
    text-transform: uppercase;
    box-sizing: border-box;
    .switch {
      margin: 0 0 0 10px;
    }
    & > div {
      width: 40%;
      display: flex;
    }
    & > div:first-child {
      justify-content: flex-end;
    }
    & > div:last-child {
      justify-content: flex-start;
    }
    & > div span {
      text-align: center;
      line-height: initial;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: default;
    }
  }
  &__info {
    grid-area: info;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &-item {
      line-height: initial;
    }
  }
  &__what-is {
    grid-area: whatis;
    width: 100%;
    &-icon {
      margin: 0 0.2rem;
    }
  }
  &__chalkboard {
    grid-area: chalkboard;
    height: 100%;
  }
}

@media screen and (min-width: $break-point-min) {
  .xg {
    grid-template-areas:
      'switch switch switch switch switch'
      'whatis whatis chalkboard chalkboard chalkboard'
      'info info chalkboard chalkboard chalkboard'
      '. . chalkboard chalkboard chalkboard';

    &__team-switch {
      width: 100%;
    }

    &__info {
      flex-flow: column nowrap;
      border: solid 1px #d8d8d8;
      border-radius: 7px;
      margin: 0 0 0 2vw;
      width: 100%;
      max-width: 260px;
      height: min-intrinsic;
      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: solid 1px #d8d8d8;
        padding: 0.8em 0;
        &-left,
        &-right {
          width: 100%;
        }
        &-left {
          text-align: left;
          padding-left: 1em;
        }
      }
      &-item:last-child {
        border-bottom: none;
      }
    }

    &__what-is {
      margin: 3vh 0 3vh 2vw;
      max-width: 260px;
      &-btn {
        height: 1rem;
      }
      &-text {
        font-size: 0.8em;
      }
    }

    &__chalkboard {
      width: 120%;
      margin-left: -35px;
    }
  }
}

@media screen and (max-width: $break-point-max) {
  .xg {
    grid-template-areas:
      'switch switch switch switch'
      'info info info info'
      'whatis whatis whatis whatis'
      'chalkboard chalkboard chalkboard chalkboard';

    &__team-switch {
      width: 100%;
      & > div span {
        min-width: 35%;
      }
    }

    &__info {
      flex-flow: row nowrap;
      width: 90%;
      height: 7vh;
      max-height: 42px;
      border-bottom: dotted 1px black;
      &-item {
        width: 60%;
        height: 1em;
        &-left,
        &-right {
          display: inline;
          white-space: nowrap;
        }
      }

      &-item:nth-child(2) {
        width: 100%;
        border: dotted 1px black;
        border-top: none;
        border-bottom: none;
      }
    }

    &__what-is {
      margin: 1.7vw 0 0;
      &-btn {
        height: 1.2rem;
      }
      &-text {
        font-size: 0.8rem;
      }
    }

    &__chalkboard {
      width: 90%;
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 390px) {
  .xg {
    &__what-is {
      margin: 3vw 0 0;
    }
    &__chalkboard {
      margin-top: 20px;
      width: 100%;
    }
    &__info {
      &-item {
        &-left,
        &-right {
          font-size: 0.85rem;
        }
      }

      &-item:nth-child(2) {
        width: 100%;
        border: dotted 1px black;
        border-top: none;
        border-bottom: none;
      }
    }
  }
}
</style>
