<template>
  <div class="box-tooltip" :style="positionTooltip" :class="showTooltip">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'tooltip',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    positionTooltip() {
      // let factor = this.displayWidth > 400 ? (this.displayWidth > 1100 ? 210 : 100) : this.data.x > 230 ? 50 : 0;
      return {
        top: `${this.data.y}px`,
        // left: `${this.data.x - factor}px`,
        left: `${this.data.x}px`,
        transform: this.displayWidth > 800 ? `translate(130%, 90%)` : `translate(-10%, 186%`,
      };
    },
    showTooltip() {
      return {
        visible: this.data.visible,
      };
    },
  },
};
</script>

<style scoped>
.box-tooltip {
  border-radius: 4px !important;
  pointer-events: none;
  min-width: 150px;
  position: absolute;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  z-index: 5;
  margin: 0 auto;
  color: white;
  display: none;
}
.box-tooltip.visible {
  display: block;
}
</style>
